/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                var can_ajax;
                if (Modernizr.history && $("#wpadminbar").length === 0) { // ask Modernizr if the browser supports history and we are not logged in. 
                    can_ajax = true;
                } else {
                    can_ajax = false;
                }
                //////////////////////////////////////////////
                var contentResizeTimer;

           
              


                // make viewport better on small screens
                var setup_viewport = function() {
                    if (screen.width <= 700) {
                        $("meta[name='viewport']").attr("content", "initial-scale=0.7, minimum-scale=0.7");
                    } else {
                        $("meta[name='viewport']").attr("content", "width=device-width, initial-scale=1");
                    }
                };
                $(window).resize(setup_viewport);
                setup_viewport();


                ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

                //////////////// start of gsap on home slider

        $(window).load(function() {
         $(".usp_circle:even").css("border", "solid 2px #89c43f" );
         $(".usp_circle:odd").css("border", "solid 2px #75b4e0" );
          


                
        });

         $(window).load(function() {
         $(".usp_circle h4:odd").css("border-bottom", "solid 1px #89c43f" );
         $(".usp_circle h4:even").css("border-bottom", "solid 1px #75b4e0" );
          


                
        });


     $(window).load(function() {
         $(".services_img img:even").css("border", "solid 3px #89c43f" );
         $(".services_img img:odd").css("border", "solid 3px #75b4e0" );
          


                
        });




///////////////////////////////////////////////////////////////////////////////////////////////////////

                


                 


               
             

                function init() {
                    // Do this when a page loads.                    
                    $("body").css("cursor", "auto");
                    if (document.readyState === "complete") {
                        //console.log("page has allready loaded so .ajax() got us here.");                       
                        $(window).off("scroll", arrow_controlls);
                        $(window).scroll(side_nav_move);
                        // $(window).scroll(detect_play);
                        hard_load_and_ajax_page_change();
                    } else {
                        //console.log("was a hard land");
                        $(document).ready(function() {
                            stuff_to_do_on_load();
                        });

                    }

                }

              
                


           
           



                // Code for fixing example.dev:3000 issue. 
                if (window.location.href.indexOf("ashbank.dev") > -1) { // if we are on example.dev
                    $("a:not(figure a)").on("click", function() { //not() can be used to stop it breaking over a hrefs like photo swipe gallery.
                        var currentURL = $(this).attr("href");
                        if (currentURL.toLowerCase().indexOf("wp-admin") > -1 || currentURL.toLowerCase().indexOf("logout") > -1 || currentURL.toLowerCase().indexOf("wp-content") > -1) {
                            // if user clicks dashboard links from front end remove the port so it will not redirect to the dash with the 3000
                            currentURL = currentURL.replace(":3000", "");
                            window.location.href = currentURL;
                            return false;
                        } else if (currentURL.toLowerCase().indexOf("ashbank.dev") > -1 && currentURL.toLowerCase().indexOf(":3000") < 0) {
                            // if on example.dev and is frontend (not admin page) and the port is not already set then add the port
                            currentURL = currentURL.replace(document.location.hostname, document.location.hostname + ":3000");
                            window.location.href = currentURL;
                            return false;
                        }
                    });
                }
                // END ALL
// listing pages
    // fix headings 
    $( ".listing_text_area p" )
    .contents()
    .filter(function() {        
      return this.nodeType === 3 && $(this).text().trim() === "Location" && $(this).next().is("br") && $(this).next().next().is("br");
    })
    .wrap( "<h3></h3>" );

  $( ".listing_text_area p" )
    .contents()
    .filter(function() {        
      return this.nodeType === 3 && $(this).text().trim() === "Description" && $(this).next().is("br") && $(this).next().next().is("br");
    })
    .wrap( "<h3></h3>" );

  $( ".listing_text_area p" )
    .contents()
    .filter(function() {        
      return this.nodeType === 3 && $(this).text().trim() === "Summary" && $(this).next().is("br") && $(this).next().next().is("br");
    })
    .wrap( "<h3></h3>" );

  $( ".listing_text_area p" )
    .contents()
    .filter(function() {        
      return this.nodeType === 3 && $(this).text().trim() === "Specification" && $(this).next().is("br") && $(this).next().next().is("br");
    })
    .wrap( "<h3></h3>" );



    $.each($( ".listing_text_area p h3" ), function(){
        if ($(this).prev().is("br")) {
            $(this).prev().remove();
        }
        if ($(this).next().is("br")) {
            $(this).next().remove();
        }
    });    

    // image slider with thumbs
    $.each($(".picGall_images div"), function(){
        $(this).attr("style", $(this).attr("data-style")).removeAttr("data-style");
    });
    $(document).on("click",".picGall_images .small_image, .picGall .glyphicon-menu-left, .picGall .glyphicon-menu-right", function(){
        var my_this = this;
        $(".picGall_images div").eq(0).fadeTo(180, 0.12);
        setTimeout(function(){
            if ($(my_this).is(".small_image")) {                    
                $(".picGall_images div").removeClass("chris_active").eq(0).replaceWith($(my_this).addClass("chris_active").clone().addClass("big_image").removeClass("small_image chris_active").fadeTo(0, 0.12));
            }
            if ($(my_this).is(".glyphicon-menu-left")) {
                if ($(".picGall_images .chris_active").prev().is(".big_image")) {
                    $(".picGall_images div").removeClass("chris_active").eq(0).replaceWith($(".picGall_images div").last().addClass("chris_active").clone().addClass("big_image").removeClass("small_image chris_active").fadeTo(0, 0.12));
                } else {
                    $(".picGall_images div").eq(0).replaceWith($(".picGall_images .chris_active").removeClass("chris_active").prev().addClass("chris_active").clone().addClass("big_image").removeClass("small_image chris_active").fadeTo(0, 0.12));
                }           
            }
            if ($(my_this).is(".glyphicon-menu-right")) {
                if ($(".picGall_images .chris_active").is(':last-child')) {             
                    $(".picGall_images div").removeClass("chris_active").eq(0).replaceWith($(".picGall_images div").eq(1).addClass("chris_active").clone().addClass("big_image").removeClass("small_image chris_active").fadeTo(0, 0.12));
                } else {                
                    var new_active = $(".picGall_images .chris_active").index() + 1;
                    $(".picGall_images div").removeClass("chris_active").eq(0).replaceWith($(".picGall_images div").eq(new_active).clone().addClass("big_image").removeClass("small_image chris_active").fadeTo(0, 0.12));
                    $(".picGall_images div").eq(new_active).addClass("chris_active");
                }   
            }
            $(".picGall_images div").eq(0).fadeTo(180, 1.00);
        }, 270);
    });



// end listing cats


            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
